
import { Component, Vue } from 'vue-property-decorator';
import EmployeedemandadmitMain from "@/components/employeedemandadmit/Main.vue";

@Component({
  components: {
    EmployeedemandadmitMain,
  }
})
export default class EmployeeEach extends Vue {

}
