import { Component, Mixins } from 'vue-property-decorator';
import utilMixins from '@/mixins';
import vuex_data from '@/vuex/vuex_data';
import vuex_manager from '@/vuex/vuex_manager';
import vuex_table from '@/vuex/vuex_table';

@Component
export default class mixinEmployeeDemandAdmit extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.employee_demand_manager;
  }
  get global() {
    return vuex_data.employee_demand_global.get(this);
  }
  get employeely() {
    return vuex_data.employee_demand_employeely.get(this);
  }

  get employee() {
    return this.m.edit_manager.join.object.employee;
  }
  get dependants() {
    return this.m.edit_manager.join.object.dependants;
  }
  get spouce() {
    return this.m.edit_manager.join.object.spouce;
  }
  get dependant_num() {
    return this.m.edit_manager.join.object.dependant_num;
  }

  get employee_type_auth() {
    const type_array = [
      {shift: true, attendance: true, payment: true, nencho: true},
      {shift: true, attendance: false, payment: false, nencho: false},
      {shift: true, attendance: true, payment: false, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: false},
      {shift: false, attendance: false, payment: true, nencho: true},
      {shift: false, attendance: false, payment: false, nencho: true},
    ];
    return type_array[this.employeely.old_employee.type];
  }

  get employee_id() {
    return this.query.employee_id;
  }
  set employee_id(val) {
    this.query = {employee_id: val};
  }

  get menu_options() {
    var options = {};

    options['self'] = {name: '本人情報', route: 'self_base', pages: ['self_base', 'self_address', 'self_bank', 'self_tax', 'self_spouce', 'self_dependant']};
    if (this.global.employee_infos.length != 0) options['info'] = {name: 'カスタム情報', route: 'info', pages: ['info']};

    return options;
  }

  get menu_sub_options() {
    var options = {};

    options['self'] = []
    options['self'].push({name: '基本情報', route: 'self_base', pages: ['self_base']});
    if (this.employee_type_auth.payment || this.employee_type_auth.nencho) options['self'].push({name: '住所情報', route: 'self_address', pages: ['self_address']});
    if (this.employee_type_auth.payment) options['self'].push({name: '振込口座', route: 'self_bank', pages: ['self_bank']});
    if (this.employee_type_auth.payment || this.employee_type_auth.nencho) options['self'].push({name: '税額表区分（甲乙）', route: 'self_tax', pages: ['self_tax']});
    if ((this.employee_type_auth.payment || this.employee_type_auth.nencho) && this.employeely.employee.tax_type == 0) options['self'].push({name: '配偶者情報', route: 'self_spouce', pages: ['self_spouce']});
    if ((this.employee_type_auth.payment || this.employee_type_auth.nencho) && this.employeely.employee.tax_type == 0) options['self'].push({name: '扶養親族情報', route: 'self_dependant', pages: ['self_dependant']});

    if (this.global.employee_infos.length != 0) {
      options['info'] = [];
      options['info'].push({name: 'カスタム情報', route: 'info', pages: ['info']});
    }

    return options;
  }
}
