
import { Component, Mixins, Watch } from 'vue-property-decorator';
import mixinEmployeeDemandAdmit from '@/mixins/mixinEmployeeDemandAdmit';
import { Provide } from '@/library/provide';
import LProgress from "@/components/l/Progress.vue";
import LPage from "@/components/l/Page.vue";
import LBread from "@/components/l/Bread.vue";
import LSidemenu from "@/components/l/Sidemenu.vue";
import FSidemenuscroll from "@/components/f/Sidemenuscroll.vue";
import LContent from "@/components/l/Content.vue";
import EmployeedemandadmitBase from "@/components/employeedemandadmit/Base.vue";
import EmployeedemandadmitAddress from "@/components/employeedemandadmit/Address.vue";
import EmployeedemandadmitBank from "@/components/employeedemandadmit/Bank.vue";
import EmployeedemandadmitTax from "@/components/employeedemandadmit/Tax.vue";
import EmployeedemandadmitSpouce from "@/components/employeedemandadmit/Spouce.vue";
import EmployeedemandadmitDependants from "@/components/employeedemandadmit/Dependants.vue";
import EmployeejoinEmployeeinfo from "@/components/employeejoin/Employeeinfo.vue";
import FButton from "@/components/f/Button.vue";

@Component({
  components: {
    LProgress,
    LPage,
    LBread,
    LSidemenu,
    FSidemenuscroll,
    LContent,
    EmployeedemandadmitBase,
    EmployeedemandadmitAddress,
    EmployeedemandadmitBank,
    EmployeedemandadmitTax,
    EmployeedemandadmitSpouce,
    EmployeedemandadmitDependants,
    EmployeejoinEmployeeinfo,
    FButton,
  }
})
export default class Main extends Mixins(mixinEmployeeDemandAdmit) {
  created() {
    this.m.initEditManager({name: 'join'});
    this.fetchedGlobal(this.fetched);
  }

  admit() {
    if (
      (this.employee.carfare_type != 0) !== (this.employeely.old_employee.carfare_type != 0)
      || (this.employee.carfare_type != 0 && (this.employee.is_train !== this.employeely.old_employee.is_train || this.employee.is_car !== this.employeely.old_employee.is_car))
      || (this.employee.carfare_type != 0 && this.employee.is_car && this.employee.km !== this.employeely.old_employee.km)
      || (this.employee.carfare_type != 0 && (this.employee.is_train || this.employee.is_car) && this.employee.route !== this.employeely.old_employee.route)
    ) {
      this.m.update({name: 'admit_change_carfare', args: {
        ch_employee_id: this.employee.id,
      }, self: this});
    } else {
      this.m.update({name: 'admit', args: {
        ch_employee_id: this.employee.id,
      }, self: this});
    }
  }
  deny() {
    this.m.update({name: 'deny', args: {
      ch_employee_id: this.employee.id,
    }, self: this});
  }

  @Watch('fetched')
  fetchedGlobal(fetched) {
    if (fetched) {
      this.m.edit_manager.join.edit({
        employee: this.employeely.employee,
        spouce: this.employeely.spouce,
        dependants: this.employeely.dependants,
        dependant_num: Object.keys(this.employeely.dependants).length,
        each_employee_infos: this.employeely.each_employee_infos,
      });
    }
  }

  get fetched() {
    return !!this.employeely;
  }
      
  get employee() {
    return this.m.edit_manager.join.object.employee;
  }
  get dependants() {
    return this.m.edit_manager.join.object.dependants;
  }
  get spouce() {
    return this.m.edit_manager.join.object.spouce;
  }
  get dependant_num() {
    return this.m.edit_manager.join.object.dependant_num;
  }


  @Provide('employee_type')
  get employee_type() {
    return this.employee.type;
  }

  @Provide('is_edit')
  get is_edit() {
    return false;
  }
  @Provide('is_add')
  get is_add() {
    return false;
  }
}
